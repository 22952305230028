import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      path : ['AUTH']
    }),
  ],
  state: {
    MAINTENANCE : false,
    AUTH : false
  },
  mutations: {
    login(state) {
      state.AUTH = true
    },
    logout(state) {
      state.AUTH = false
    }
  },
  actions: {
  },
  modules: {
  }
})

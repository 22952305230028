<template>
    <footer class="footer-container"  v-if="$route.name != 'Maintenance' && $route.name != 'Inspection'">
        FACIL’IMMO © {{ getCurrentYear() }} - 
        Tous droits réservés -
        <router-link to="/mentions-legales">Mentions légales</router-link> -
        Design & Development by <a href="https://www.synthes3dweb.com/" target="_blank">SYNTHES’3D WEB</a>
    </footer>
</template>

<script>
export default {
    name : 'Footerapp',
    methods : {
        getCurrentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>

<style lang="scss" scoped>
    .footer-container {
        background:#393b3c;
        text-align: center;
        padding:25px 20px;
        color:#fff;
        font-size:15px;
    }

    .footer-container a {
        color:#fff;
        margin:0 3px;
        text-decoration: none;
        cursor: pointer;
    }

    .footer-container a:hover {
        text-decoration: underline;
    }

    @media screen and (max-width:768px) {
        .footer-container {
            font-size: 13px;
        }
    }

</style>
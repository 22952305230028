<template>
  <div id="app">
    <HeaderApp></HeaderApp>
    <router-view/>
    <FooterApp></FooterApp>
  </div>
</template>

<script>
import HeaderApp from './components/HeaderApp'
import FooterApp from './components/FooterApp'

export default {
  name : 'App',
  components : {
    HeaderApp,
    FooterApp
  }  
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');

body {
  margin:0;
  padding:0;
}

#app {
  font-family: $font;
}

.main-container {
  min-height: calc(100vh - 88px);
}

.banner {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-section {
  margin:0;
  padding:80px 0;
  width:100%;
  display: flex;
}

.section-gray {
  background:$bg-secondary;
}

.section-reverse {
  flex-direction: row-reverse;
}

.col-section {
  width:50%;
  min-height:525px;
  display: flex;
  align-items: center;
  position: relative;
}

.gradient {
  position: absolute;
  top:0;
  bottom:0;
  right:0;
  left:50%;
  background:linear-gradient(90deg, rgba(29, 113, 185, 0) 0%, rgba(29, 113, 185, 0.6) 100%)
}

.section-reverse .gradient {
  left:0;
  right:50%;
  background:linear-gradient(90deg, rgba(29, 113, 185, 0.6) 0%, rgba(29, 113, 185, 0) 100%)
}

.text-container {
  max-width: 635px;
  width:80%;
  margin:0 auto;
}

.text-container h2 {
  color:$main-color;
  font-size:30px;
  font-weight: 500;
  margin:0 20px;
}

.text-container p {
  color:$text-color;
  font-size:18px;
  text-align: justify;
  line-height: 25px;
  font-weight:400;
}

.btn {
  padding:7px 30px;
  background:$main-color;
  border-radius:10px;
  color:#fff;
  text-transform: uppercase;
  font-size:20px;
  text-decoration: none;
  cursor: pointer;
  transition:0.5s;
  border:1px solid $main-color;
  display: inline-block;
}

.btn:hover{
  text-decoration: none !important;
  background:#fff;
  color:$main-color;
}


@media screen and (max-width:768px) {
  .text-container h2 {
    font-size: 25px;
    text-align: center;
  }

  .text-container p {
    font-size: 15px;
  }

  .btn {
    font-size: 15px;
  }

  .main-section {
    flex-direction: column-reverse;
  }

  .col-section {
    width: 100%;
    min-height: 300px;    
  }

  .col-section:nth-child(2) {
    margin-bottom: 20px;
  }

  .gradient {
    display: none;
  }

  .text-container .btn {
    margin-left:calc(50% - 100px);
  }

}
</style>

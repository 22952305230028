import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta : {
      maintenance : false
    }
  },
  {
    path: '/premium',
    name: 'Premium',
    component: () => import('../views/Premium.vue'),
    meta : {
      maintenance : false
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta : {
      maintenance : false
    }
  },
  {
    path: '/mentions-legales',
    name: 'LegalNotices',
    component: () => import('../views/LegalNotices.vue'),
    meta : {
      maintenance : false
    }
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/Maintenance.vue'),
    meta : {
      maintenance : true
    }
  },
  {
    path: '/inspection',
    name: 'Inspection',
    component: () => import('../views/Inspection.vue'),
    meta : {
      maintenance : true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isMaintenance = store.state.MAINTENANCE;
  const isLoged = store.state.AUTH;
  const requiresMaintenance = to.matched.some((record) => record.meta.maintenance);
  if (!requiresMaintenance && isMaintenance && !isLoged) next("/maintenance");
  else next();
});

export default router

import { extend } from "vee-validate";
import {
  required,
  max,
  min,
  email,
  length,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Ce champ est requis",
});

extend("max", {
  ...max,
  message: (_, { length }) =>
    `Ce champ ne peut pas contenir plus de ${length} caractères !`,
});

extend("length", {
  ...length,
  message: (_, { length }) =>
    `Ce champ doit contenir au moins ${length} caractères !`,
});

extend("min", {
  ...min,
  message: (_, { length }) =>
    `Ce champs doit contenir au moins ${length} caractères`,
});

extend("email", {
  ...email,
  message: "Format d'email non valide",
});



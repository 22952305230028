<template>
    <header class="header-container"  v-if="$route.name != 'Maintenance' && $route.name != 'Inspection'">
        <div class="logo-container">
            <img src="./../assets/logo-premium-header.png" alt="logo Facil'Immo" @click="$router.push('/')" v-if="$route.name == 'Premium'" />
            <img src="./../assets/logo-failimmo.png" alt="logo Facil'Immo" @click="$router.push('/')" v-else />
        </div>
        <div id="nav" :class="{ premium : $route.name == 'Premium' }">
            <div class="menu-link">
                <router-link to="/">Home</router-link>
                <div class="menu-highlight"></div>
            </div>
            <div class="menu-link premium">
            <router-link to="/premium">Premium</router-link>
                <div class="menu-highlight"></div>
            </div>
            <div class="menu-link">
            <router-link to="/contact">Contact</router-link>
                <div class="menu-highlight"></div>
            </div>
        </div>
        <!-- MENU RESP -->
        <div class="menu-resp">
            <div class="burger-nav" @click="open = !open " :class="open ? 'open' : ''">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav class="menu-header-resp-content" v-if="open">
                <router-link to="/">Home</router-link>
                <router-link class="link-premium" to="/premium">Premium</router-link>
                <router-link to="/contact">Contact</router-link>
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    name : 'HeaderApp',
    data(){
        return {
            open : false
        }
    },
    watch : {
         '$route.name' : function() {
             this.open = false
         }
    }
}
</script>

<style lang="scss" scoped>
    .header-container {
        display: flex;
        justify-content: space-between;
        height:100px;
        align-items: center;
        padding:0 100px;
        position:fixed;
        top:0;
        left:0;
        right:0;
        border-bottom:1px solid rgba($text-color, 0.5);
        background:rgba(0,0,0,0.5);
        z-index:999;
    }

    #nav {
        display: flex;
    }

    .menu-link {
        margin:0 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height:40px;
        overflow: hidden;
    }

    .menu-link a {
        color:#fff;
        text-decoration: none;
        font-size:20px;
        margin-bottom:7px;
        transition:0.5s;
    }

    .menu-highlight {
        width:80%;
        height:3px;
        background:#fff;
        margin-top:80px;
        transition:0.5s;
    }

    .menu-link:hover .menu-highlight, a.router-link-exact-active + .menu-highlight{
        margin-top:0;
    }

    

    a.router-link-exact-active {
        font-weight:500;
    }

    .logo-container img {
        cursor: pointer;
        height:80px;
    }

    .premium a.router-link-exact-active + .menu-highlight, .menu-link.premium:hover .menu-highlight {
        background:$secondary-color;
    }

    .premium a.router-link-exact-active, .menu-link.premium:hover a {
        color:$secondary-color;
    }

    .menu-resp {
        display: none;
    }

    @media screen and (max-width:1120px) {
        .header-container {
            padding:0 20px;
        }
    }

    @media screen and (max-width:900px) {
        #nav {
            display: none;
        }

        .menu-resp {
            display: flex;
        }

        .burger-nav {
            width: 40px;
            height: 40px;
            position: relative;
            margin: 50px auto;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
        }

        .burger-nav span {
            display: block;
            position: absolute;
            height: 7px;
            width: 100%;
            background: #fff;
            border-radius: 7px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
        }

        .burger-nav span:nth-child(1) {
            top: 0px;
        }

        .burger-nav span:nth-child(2),
        .burger-nav span:nth-child(3) {
            top: 14px;
        }

        .burger-nav span:nth-child(4) {
            top: 28px;
        }

        .burger-nav.open span:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }

        .burger-nav.open span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .burger-nav.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        .burger-nav.open span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }

        .menu-header-resp-content {
            position: fixed;
            top: 70px;
            width: 300px;
            right: 0;
            background: #fff;
            display: flex;
            flex-direction: column;
        }

        .menu-header-resp-content a {
            width: calc(100% - 40px);
            border: none;
            height: 40px;
            display: flex;
            justify-content: flex-start;
            padding: 0 20px;
            align-items: center;
            text-decoration: none;
            margin:0;
            color:$main-color;
        }

        .menu-header-resp-content a.router-link-exact-active {
            background:$main-color;
            color:#fff;
        }

        .menu-header-resp-content a.router-link-exact-active.link-premium {
            background:$secondary-color;
        }

    }
    
    @media screen and (max-width:768px) {
        .logo-container img {
            height:50px;
        }

        .header-container {
            height:70px;
        }
    }
</style>